<template>
  <div>
       <Spinner />
       <SubHeaderBlock
          :page-number = pageNumber
          :page-count = count
          :reset-pag = resetPag
          @nextPage = "IncPageNum"
          @prevPage = "DecPageNum"
       />
       <FilterRoutes
        @search = "search"
        @getRoutes = 'getRoutes'
       > 
            <template slot="logbook">
              <button class="header__btn right-item default-btn flex" @click="exportToPdf()">
                <b-icon class="filter__download-icon" icon="download" font-scale="1" />
                &nbsp;&nbsp;
               Журнал учета
            </button>
            </template>
       </FilterRoutes>
       <TableBlock 
          v-if="items"
          :items = items
          :page-number = pageNumber
          @showModal = "changeShowModal"
        />
       <Modal 
       v-if="showModal" 
       :index="index"
       :order-id="orderId"
       :driver-id = "driverId"
       @showModal = 'changeShowModal'
       />

       <Calendar  v-if="showCalendar" @showCalendar = 'showCalendar = !showCalendar' />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
   components: {
    TableBlock: () => ({
      component: import("./components/table"),
    }),
    SubHeaderBlock: () => ({
      component: import("./components/subHeader"),
    }),
    FilterRoutes: () => ({
      component: import("../../components/filter/filterMedic"),
    }),
    Modal: () => ({
      component: import("./components/modal"),
    }),
    Spinner: () => ({
      component: import("../../components/spinner/index"),
    }),
    Calendar: () => ({
      component: import("./components/modalCalendar"),
    }),
  },
  data() {
    return {
      showModal : false,
      orderId: 0,
      index: null,
      pageNumber: 1,
      items: null,
      count: 0,
      countSearch : 0,
      resetPag: false,
      showCalendar: false,
      driverId: 0,
    }
  },
  methods: {
    changeShowModal(orderData = []) {     // Показать модалку с передачей данных
      this.index = orderData.index;       // индекс объекта в массиве чтобы не передавать, большие данные через props
      this.orderId = orderData.orderId;   // номер заказа
      this.driverId = orderData.driverId
      this.showModal = !this.showModal;
    },
    IncPageNum(){
        this.pageNumber++
      },
    DecPageNum(){
        this.pageNumber--;
      },
    async getRoutes(){
      await this.$store.dispatch('getRoutesDrivers', {  // получить маршруты водителей по 10 шт
          pageNumber : this.pageNumber  // порции данных определяется по номеру страницы на стороне бэка
        });
        this.items = this.RoutesDrivers;  
        if(this.items[0]){
          this.count = this.items[0].count  // кол-во записей
        }
    },

    exportToPdf (){
      this.showCalendar = !this.showCalendar
    },
    search(searchVal){
        this.countSearch = searchVal.value.length
    }
  },
  computed: {
        ...mapState(['RoutesDrivers']),
      },
  watch: {
      pageNumber: function(){
          if(this.countSearch > 0){
            const from = ((this.pageNumber - 1) * 10);
            let to = ((this.pageNumber - 1) * 10) + 9;
            this.items = this.RoutesDrivers.slice(from, to);
            this.count = this.RoutesDrivers.length
          }else{
            this.getRoutes();        
          } 
      },
      RoutesDrivers: function(){
        this.items = this.RoutesDrivers;
        if(this.countSearch > 0){
           this.count = this.RoutesDrivers.length
           this.pageNumber = 1
           this.resetPag = !this.resetPag
        }   
      },
  },
  created() {
    this.getRoutes();
  }
}

</script>

<style scoped>
</style>
